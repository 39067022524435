<template>
  <div class="hello">
    <p>
      After four years as an agency, The Taproom now operates as a consultancy owned by Kelly Vaughn, Shopify
      Expert since 2014.
    </p>
    <p v-if="acceptingClients">
      I'm taking on a very limited number of development and consulting projects. Current availability starts in
      <AvailabilityDate />.
    </p>
    <p v-else>
      I'm not currently taking new development clients on at this time, but am open to hourly consulting. Get in touch to learn more. (Hourly consulting rate is $400/hr.)
    </p>
    <template v-if="acceptingClients">
      <h3>Services</h3>
      <ul>
        <li>Shopify theme development</li>
        <li>Shopify app development</li>
        <li>Store migration to Shopify</li>
      </ul>
    </template>
    <h3>Get in touch</h3>
    <ul>
      <li>
        <a href="mailto:kelly@thetaproom.com" target="_blank" rel="noopener"
          >Send me an email</a
        >
      </li>
      <li>
        <a href="https://calendly.com/kvlly" target="_blank">Schedule a consulting session</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import AvailabilityDate from './AvailabilityDate.vue'
  
  export default {
    name: 'HelloWorld',
    props: {
      msg: String,
    },
    data() {
      return {
        acceptingClients: false
      }
    },
    components: {
     AvailabilityDate
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    max-width: 600px;
    margin: 0 auto;
  }
  p {
    line-height: 1.6;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 20px 0;
  }
  a {
    color: #c85817;
  }
</style>
